import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import HeroTitle from "../../../components/HeroTitle";
import { globalClasses } from "../../../components/layout/MainLayout/styles";
import { GOOGLE_POLICY_HERO_SECTION } from "../../../util/constants";
import { StyledSectionWrapper } from "./styles";

const Hero = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <StyledSectionWrapper
      padding="normal"
      sectionId={GOOGLE_POLICY_HERO_SECTION}
      className={globalClasses.innoBlueGradient}
    >
      <Container>
        <Grid
          item
          sm={12}
          md={6}
          justifyContent={isMobile ? "center" : "flex-start"}
          container
        >
          <HeroTitle>Mentions légales</HeroTitle>
        </Grid>
      </Container>
    </StyledSectionWrapper>
  );
};

export default Hero;
