import SectionWrapper from "../../../components/layout/SectionWrapper";
import styled from "../../../util/styled";

const PREFIX = "Hero";

export const classes = {
  typo: `${PREFIX}-typo`,
};

export const StyledSectionWrapper = styled(SectionWrapper)(({ theme }) => ({
  [`& .${classes.typo}`]: {
    color: theme.palette.common.white,
  },
}));
