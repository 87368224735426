import SectionWrapper from "../../../components/layout/SectionWrapper";
import styled from "../../../util/styled";

const PREFIX = "Hero";

export const classes = {
  wrapper: `${PREFIX}-wrapper`,
  fontWeight: `${PREFIX}-fontWeight`,
};

export const StyledSectionWrapper = styled(SectionWrapper)(({ theme }) => ({
  [`& .${classes.wrapper}`]: {
    margin: theme.spacing(3, 0),
    "&:first-of-type": {
      marginTop: 0,
    },
    "&:last-child": {
      marginBottom: 0,
    },
  },

  [`& .${classes.fontWeight}`]: {
    fontWeight: 600,
  },
}));
