import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { Link as GatsbyLink } from "gatsby";
import React from "react";
import { LEGALS_CONTENT_SECTION } from "../../../util/constants";
import { classes, StyledSectionWrapper } from "./styles";

const Content = () => {
  return (
    <StyledSectionWrapper sectionId={LEGALS_CONTENT_SECTION}>
      <Container>
        <div className={classes.wrapper}>
          <Typography variant="h6" className={classes.fontWeight}>
            Le site www.innovance-solutions.com est développé et édité par :
          </Typography>
          <Typography variant="body2" gutterBottom>
            <span className={classes.fontWeight}>
              Innovance - SAS au capital de 10 710 euros
            </span>
            <br />
            10, Chemin du maquis <br />
            74000 ANNECY <br />
            Tél : 04 50 10 61 37
          </Typography>
          <Typography variant="body2" gutterBottom>
            Email :{" "}
            <Link component={GatsbyLink} to="/contact">
              nous contacter
            </Link>
            <br />
            SIRET : 51814705300012
            <br />
            R.C.S Annecy - APE : 6202A
            <br />
            TVA intracommunautaire FR69518147053
          </Typography>
          <Typography variant="body2" gutterBottom>
            Directeur de la publication : Thierry Wattel
          </Typography>
          <Typography variant="body2" gutterBottom>
            L'ensemble de ce site relève des législations françaises et
            internationales sur le droit d'auteur et la propriété
            intellectuelle. Tous les droits de reproduction sont réservés, y
            compris pour les documents iconographiques et photographiques.
          </Typography>
        </div>
        <div className={classes.wrapper}>
          <Typography variant="h6" className={classes.fontWeight}>
            Hébergement :
          </Typography>
          <Typography variant="body2" gutterBottom>
            <span className={classes.fontWeight}>Netlify</span> <br />
            610 22nd Street, Suite 315, San Francisco, CA 94107
            <br />
            Site web :{" "}
            <Link
              underline="hover"
              target="_blank"
              rel="noopener"
              href="https://www.netlify.com"
            >
              https://www.netlify.com
            </Link>
          </Typography>
        </div>
        <div className={classes.wrapper}>
          <Typography variant="h6" className={classes.fontWeight}>
            Loi du 6 janvier 1978 relative à l'informatique et aux libertés :
          </Typography>
          <Typography variant="body2" gutterBottom>
            Les informations recueillies font l’objet d’un traitement
            informatique destiné à la collecte d'informations. Innovance est
            l'unique destinataire des données.
          </Typography>
          <Typography variant="body2" gutterBottom>
            Conformément à la loi « informatique et libertés » du 6 janvier 1978
            modifiée en 2004, vous bénéficiez d’un droit d’accès et de
            rectification aux informations qui vous concernent, que vous pouvez
            exercer en vous adressant à Innovance 178 Route de Cran Gevrier
            74650 Chavanod
          </Typography>
          <Typography variant="body2" gutterBottom>
            Vous pouvez également, pour des motifs légitimes, vous opposer au
            traitement des données vous concernant.
          </Typography>
        </div>
        <div className={classes.wrapper}>
          <Typography variant="h6" className={classes.fontWeight}>
            Images & Illustrations :
          </Typography>
          <Typography variant="body2" gutterBottom>
            © 2021 Katerina Limpitsouni -{" "}
            <Link
              underline="hover"
              target="_blank"
              rel="noopener"
              href="https://undraw.co/"
            >
              unDraw
            </Link>{" "}
            - Illustrations issues d'un projet open-source
          </Typography>
        </div>
      </Container>
    </StyledSectionWrapper>
  );
};

export default Content;
